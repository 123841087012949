const initialState = {
  loading: false,
  mintingInProgress: false,
  account: null,
  isPremint: false,
  isMainMint: false,
  contractApproval: false,
  contractAllowance: false,
  smartContractToken: null,
  hasPremintAccess: false,
  smartContractCSS: null,
  web3: null,
  errorMsg: "",
  totalMint: 0,
  mintPrice: 0,
  paused: false,
  tokenIds: []
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        smartContractToken: action.payload.smartContractToken,
        smartContractCSS: action.payload.smartContractCSS,
        web3: action.payload.web3,
      };
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload.account,
      };
    case "APPROVAL_REQUEST":
      return {
        ...state,
        loading: true
      };
    case "APPROVAL_SUCCESS":
      return {
        ...state,
        loading: false,
        contractApproval: true
      };
    case "APPROVAL_FAILED":
      return {
        ...state,
        loading: false,
        contractApproval: false
      };
    case "ALLOCATION_SUCCESS":
      return {
        ...state,
        contractAllowance: true
      };
    case "ALLOCATION_FAILED":
      return {
        ...state,
        contractAllowance: false
      };
    case "MAIN_MINT_ACTIVE":
      return {
        ...state,
        isMainMint: true
      };
    case "MAIN_MINT_INACTIVE":
      return {
        ...state,
        isMainMint: false
      };
    case "PREMINT_ACTIVE":
      return {
        ...state,
        isPremint: true
      };
    case "PREMINT_INACTIVE":
      return {
        ...state,
        isPremint: false
      };
    case "PREMINT_ACCESS_SUCCESS":
      return {
        ...state,
        isPremint: true,
        hasPremintAccess: true
      };
    case "PREMINT_ACCESS_FAIL":
      return {
        ...state,
        isPremint: true,
        hasPremintAccess: false
      };
    case "ERROR":
      return {
        ...state,
        loading: false
      };
    case "STOP_LOADING":
      return {
        ...state,
        loading: false
      };
    case "START_MINTING":
      return {
        ...state,
        mintingInProgress: true
      };

    case "FINISH_MINTING":
      return {
        ...state,
        mintingInProgress: false
      };
    case "SET_TOTAL_MINT":
      return {
        ...state,
        totalMint: action.payload
      };
    case "SET_MINT_PRICE":
      return {
        ...state,
        mintPrice: action.payload
      };
    case "SET_PAUSED":
      return {
        ...state,
        paused: action.payload
      };
    case "SET_TOKEN_IDS":
      return {
        ...state,
        tokenIds: action.payload
      };
    default:
      return state;
  }
};

export default blockchainReducer;
