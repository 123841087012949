import React, { useEffect, useState, useRef } from "react";

import { Select, Typography, Space, Spin, Progress, message, Rate } from 'antd';

import { Image } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { connect, genericError, startMinting, finishMinting, getTotalMintAndSet, getTokensOfOwner } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import { SocialIcon } from 'react-social-icons';


import * as s from "./styles/globalStyles";
import styled from "styled-components";

import { BrowserView } from 'react-device-detect';

import 'antd/dist/antd.css';
import './styles/app.css';


const { Option } = Select;
const { Text } = Typography;


export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #993739;
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :disabled,
  [disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid #7F2932;
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 390px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const { width, height } = useWindowSize()
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [remainingPremint, setRemainingPremint] = useState(0);
  const [remainingMainMint, setRemainingMainMint] = useState(0);
  const [mintAmount, setMintAmount] = useState(1);
  const [optionsMainMint, setOptionsMainMint] = useState([]);
  const [optionsPreMint, setOptionsPreMint] = useState([]);
  const [isExploding, setIsExploding] = React.useState(false);


  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    CONTRACT_ADDRESS_CSS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });


  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
    if (blockchain.account) {
      setQtyOptions();
    }
  }, [blockchain.totalMint, blockchain.account]);

  const toggleConfettiExplosion = () => {
    setIsExploding(true);
    setTimeout(() => {
      setIsExploding(false);
    }, 5000);
  }


  const setQtyOptions = () => {
    const maxPremint = CONFIG.MAX_PREMINT_PER_ADDRESS;
    const maxPerAddress = CONFIG.MAX_MINT_PER_ADDRESS;
    const totalMinted = blockchain.totalMint;
    let remainingPre = maxPremint - totalMinted;
    let remainingMain = 10;
    setRemainingMainMint(remainingMain);
    setRemainingPremint(remainingPre);
    let optnsPre = []
    let optsMain = [];
    if (remainingPre > 0) {
      for (let i = 1; i <= remainingPre; i++) {
        optnsPre.push(i);
      }
    }
    if (remainingMain > 0) {
      for (let i = 1; i <= remainingMain; i++) {
        optsMain.push(i);
      }
    }
    setOptionsMainMint(optsMain);
    setOptionsPreMint(optnsPre);
  }

  const claimNFTs = () => {

    dispatch(startMinting());
    setClaimingNft(true);
    let cost = blockchain.mintPrice;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    blockchain.smartContractCSS.methods
      .mint(mintAmount).send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS_CSS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .on("receipt", async (result) => {
        message.success('You have successfully minted!');
        toggleConfettiExplosion();
        setClaimingNft(false);
        dispatch(finishMinting());
        getData();
        setQtyOptions();
        dispatch(getTotalMintAndSet(blockchain.smartContractCSS, blockchain.account));
        dispatch(getTokensOfOwner(blockchain.smartContractCSS, blockchain.account));
      }).on('error', (error) => {
        setClaimingNft(false);
        dispatch(finishMinting());
        if (error && !error.message.includes('User denied transaction')) {
          message.error('Your transaction failed!');
          dispatch(finishMinting());
          dispatch(genericError());
        }

      })
  };

  const premintNFTs = () => {
    dispatch(startMinting());
    setClaimingNft(true);
    let cost = blockchain.mintPrice;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    blockchain.smartContractCSS.methods
      .preMint(mintAmount).send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS_CSS,
        from: blockchain.account,
        value: totalCostWei
      })
      .on("receipt", async (result) => {
        message.success('You have successfully minted!!');
        toggleConfettiExplosion();
        dispatch(finishMinting());
        setClaimingNft(false);
        getData();
        dispatch(getTotalMintAndSet(blockchain.smartContractCSS, blockchain.account));
        dispatch(getTokensOfOwner(blockchain.smartContractObjCSS, blockchain.account));

      }).on('error', (error) => {
        setClaimingNft(false);
        dispatch(finishMinting());
        if (error && !error.message.includes('User denied transaction')) {
          message.error('Your transaction failed!');
          dispatch(finishMinting());
          dispatch(genericError());
        }
      })
  };

  function onQtyChange(value) {
    setMintAmount(value);
  }

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContractToken !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  function getConfettiImages() {
    return <>
      <Image
        width={200}
        preview={false}
        width={25}
        src="https://ik.imagekit.io/3sqrfownpdc4/confetti_vmLyjmAIlcu.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642363559715"
      />
      <Image
        width={200}
        preview={false}
        width={25}
        src="https://ik.imagekit.io/3sqrfownpdc4/confetti_vmLyjmAIlcu.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642363559715"
      />
      <Image
        width={200}
        preview={false}
        width={25}
        src="https://ik.imagekit.io/3sqrfownpdc4/confetti_vmLyjmAIlcu.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642363559715"
      />
    </>
  }


  function getPreMintHtml() {
    return (
      <>
        <div>
          {getConfettiImages()}
        </div>
        <s.TextDescription
          style={{
            textAlign: "center",
            color: "var(--accent-text)",
          }}
        >
          {remainingPremint > 0 ?
            `Congratulation! You are whitelisted for pre-sale!`
            : ''}
        </s.TextDescription>
        <s.TextDescription
          style={{
            textAlign: "center",
            color: "var(--accent-text)",
          }}
        >
          {remainingPremint > 0 ? `You are eligible to mint ${remainingPremint} more now and  7 later.`
            : `You have minted 3/3. Now please wait for the public sale!`}
        </s.TextDescription>
        <s.SpacerSmall />
        <Rate disabled count={10} value={blockchain.totalMint} />

        <s.SpacerMedium />
        {remainingPremint > 0 ?
          <>
            <Space direction="horizontal">
              <Text style={{ color: '#ffffff' }}>Quantity</Text>
              <Select
                defaultValue="1"
                optionFilterProp="children"
                onChange={onQtyChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {optionsPreMint.map(option => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Space>
            <s.SpacerSmall />
            <s.Container ai={"center"} jc={"center"} fd={"row"}>
              <StyledButton
                disabled={claimingNft ? 1 : 0}
                onClick={(e) => {
                  e.preventDefault();
                  premintNFTs();
                  getData();
                }}
              >
                Mint a Star
              </StyledButton>
            </s.Container>
          </>
          : ""}
      </>
    )
  }

  function getMainMintHtml() {
    return (
      <>
        <s.SpacerSmall />
        <s.SpacerSmall />
        <>
          <Space direction="horizontal">
            <Text style={{ color: '#ffffff' }}>Quantity</Text>
            <Select
              defaultValue="1"
              optionFilterProp="children"
              onChange={onQtyChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {optionsMainMint.map(option => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Space>

          <s.SpacerSmall />
          <s.Container ai={"center"} jc={"center"} fd={"row"}>
            <StyledButton
              disabled={claimingNft ? 1 : 0}
              onClick={(e) => {
                e.preventDefault();
                claimNFTs();
                getData();
              }}
            >
              Mint a Star
            </StyledButton>
          </s.Container>
        </>


      </>
    )
  }

  function getMintHtml(blockchain) {
    if (blockchain.loading) {
      return 'loading';
    }
    if (blockchain.isPremint && !blockchain.isMainMint) {
      if (blockchain.hasPremintAccess) {
        return getPreMintHtml();
      } else {
        return <s.TextDescription
          style={{
            textAlign: "center",
            color: "var(--accent-text)",
          }}
        >
          {'Sorry! You do not have pre-sale access.'}
        </s.TextDescription>
      }

    } else {
      if (blockchain.isMainMint) {
        return getMainMintHtml();
      } else {
        return <s.TextDescription
          style={{
            textAlign: "center",
            color: "var(--accent-text)",
          }}
        >
          {'Minting has not yet started!'}
        </s.TextDescription>
      }

    }

  }

  function getConnectHTML() {
    return (
      <s.Container ai={"center"} jc={"center"}>
        <s.TextDescription
          style={{
            textAlign: "center",
            color: "var(--accent-text)",
          }}
        >
          Connect to the {CONFIG.NETWORK.NAME} network to start minting.
        </s.TextDescription>
        <s.SpacerSmall />
        <StyledButton
          onClick={(e) => {
            e.preventDefault();
            dispatch(connect());
            getData();
          }}
        >
          CONNECT
        </StyledButton>
        {blockchain.errorMsg !== "" ? (
          <>
            <s.SpacerSmall />
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--accent-text)",
              }}
            >
              {blockchain.errorMsg}
            </s.TextDescription>
          </>
        ) : null}
      </s.Container>
    )
  }

  function getProgressValue() {
    return ((data.totalSupply / CONFIG.MAX_SUPPLY) * 100).toFixed(2);
  }

  function getMintNumbers() {
    return (
      blockchain.mintingInProgress ?
        <>
          <Spin size="large" />
          <s.SpacerSmall />
          <Text style={{ color: "#ffff" }}>
            Minting in progress
          </Text>
          <Image
            style={{ animation: "zoom-in-zoom-out 2s ease infinite" }}
            width={200}
            preview={false}
            width={100}
            src="https://ik.imagekit.io/3sqrfownpdc4/mint_0X1XkIUVd.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642363560200"
          />
        </>
        :
        <>
          <s.TextTitle
            style={{
              textAlign: "center",
              fontSize: 30,
              fontWeight: "bold",
              color: "#FFBD58",
            }}
          >
            {data.totalSupply} / {CONFIG.MAX_SUPPLY}
          </s.TextTitle>
          <BrowserView>
            <Progress
              type="circle"
              className="progress"
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#87d068',
              }}
              width={100}
              percent={getProgressValue()}
              format={percent => `${percent} % minted`}
            />
          </BrowserView>
        </>
    )
  }

  function isUserConnected() {
    return !(blockchain.account === "" ||
      blockchain.smartContractCSS === null);
  }



  return (

    <s.Screen>
      <div className="top-nav">
        <div>
          <Image
            className="logo"
            width={200}
            onClick={() => {
              window.open('https://css.club/', '_blank');
            }}
            preview={false}
            width={50}
            src="https://ik.imagekit.io/3sqrfownpdc4/logo_nguCx0n8-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642363560405"
          />
          {/* <div style={{ padding: '2px' }}>CSS CLUB</div> */}
        </div>
        <SocialIcon url="https://discord.gg/cssclub" target="_blank" network="discord" />
        <SocialIcon url="https://twitter.com/cssclub_" target="_blank" network="twitter" />
        <SocialIcon url="https://www.instagram.com/cssclub_/" target="_blank" network="instagram" />
      </div>
      <>
        <div style={{
          top: '0px',
          right: '0px'
        }}>
          {isExploding && <Confetti width={width}
            height={height}
            style={{ zIndex: 0 }}
            width={width}
            height={height}
          />
          }
        </div>
      </>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "https://ik.imagekit.io/3sqrfownpdc4/bg-train_F_jJ-O5W_a.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642363559943" : null}
      >

        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <BrowserView>
              <StyledImg alt={"example"} src={"https://ik.imagekit.io/3sqrfownpdc4/avatars_1kKmSNVGMYTe.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1642363560533"} />
            </BrowserView>

          </s.Container> : ''

          <s.SpacerLarge />

          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              height: "40%",
              marginTop: "10%",
              borderRadius: 24,
              border: "4px solid #201728",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <Image
              width={200}
              onClick={() => {
                window.open(CONFIG.SCAN_LINK, '_blank');
              }}
              preview={false}
              width={25}
              src="https://ik.imagekit.io/3sqrfownpdc4/bsc_6wRyRSXxx.png?ik-sdk-version=javascript-1.4.3&updatedAt=1642363559687"
            />
            <a
              style={{
                color: '#FFBD58',
                fontStyle: "italic",
                textAlign: "center",
                wordWrap: "break-word",
                hyphens: "auto"
              }}
              href={CONFIG.SCAN_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >

              CSSC Contract
            </a>
            {blockchain.tokenIds.length > 0 ?
              <>
                <s.SpacerSmall />
                <div
                  style={{
                    color: '#FFBD58',
                    textAlign: "center",
                    wordWrap: "break-word",
                    hyphens: "auto"
                  }}
                  href={CONFIG.SCAN_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >

                  Your Minted Token Ids
                </div>
                <div style={{
                  color: '#FFF',
                  fontStyle: "italic",
                  width: "75%",
                  textAlign: "center",
                  wordWrap: "break-word",
                  hyphens: "auto"
                }}>
                  {blockchain.tokenIds.length == 1 ? blockchain.tokenIds[0] : blockchain.tokenIds.join(', ')}
                </div>
              </>
              : ''}


            <s.SpacerSmall />
            {isUserConnected() ? getMintNumbers() : ''
            }
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >

            </s.TextDescription>
            <s.SpacerSmall />
            {
              !blockchain.paused ?
                <>
                  {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                    <>
                      <s.TextDescription
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        The sale has ended. Thank you for participating. Check out our discord server!
                      </s.TextDescription>
                      <Image
                        width={200}
                        onClick={() => {
                          window.open(CONFIG.DISCORD_LINK, '_blank');
                        }}
                        preview={false}
                        width={25}
                        src="https://ik.imagekit.io/3sqrfownpdc4/discord-icon_7lghvSc0o.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1642363559884"
                      />
                      <s.SpacerSmall />
                      <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                        {CONFIG.MARKETPLACE}
                      </StyledLink>
                    </>
                  ) : (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        Mint price is {CONFIG.DISPLAY_COST} BNB
                      </s.TextTitle>
                      {
                        blockchain.loading ? <Spin size="large" /> :
                          (
                            !isUserConnected() ? (
                              getConnectHTML()
                            ) : getMintHtml(blockchain)
                          )
                      }
                    </>
                  )}
                </> :
                <>
                  <Image
                    width={200}
                    preview={false}
                    width={50}
                    src="https://ik.imagekit.io/3sqrfownpdc4/pause_ql9ePqRB8tf.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1642363560296"
                  />
                  <s.TextTitle
                    style={{ textAlign: "center", color: "#fff" }}
                  >
                    Minting is paused. Please visit <a href="https://nft.wazirx.org/collection/CSSClub?sort=recent-desc" target="_blank"> Wazir X NFT</a> to collect a star from the secondary market.
                  </s.TextTitle>
                </>
            }


            <s.SpacerMedium />
          </s.Container>


          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <BrowserView>
              <StyledImg
                alt={"Avatar"}
                src={"https://ik.imagekit.io/3sqrfownpdc4/avatars_1kKmSNVGMYTe.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1642363560533"}
                style={{ transform: "scaleX(-1)" }}
              />
            </BrowserView>
          </s.Container>

        </ResponsiveWrapper>
        <s.SpacerMedium />

      </s.Container >
    </s.Screen >
  );
}

export default App;
