// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { fetchData } from "../data/dataActions";
import { message } from 'antd';
import { BINANCE_NETWORK_MAINNET, BINANCE_NETWORK_TESTNET } from '../../network_config';

let provider;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      network: "binance", // here
      rpc: {
        56: 'https://bsc-dataseed.binance.org/'
      }
    }
  }
}

async function connectToProvider(web3Modal) {
  provider = await web3Modal.connect();
  // Subscribe to provider disconnection
  return new Web3(provider);
}


const premintAccessSuccess = () => {
  return {
    type: "PREMINT_ACCESS_SUCCESS",
  };
};

const premintAccessFailed = () => {
  return {
    type: "PREMINT_ACCESS_FAIL",
  };
};

const premintInActive = () => {
  return {
    type: "PREMINT_INACTIVE"
  };
};

const premintActive = () => {
  return {
    type: "PREMINT_ACTIVE"
  };
};
const mainMintActive = () => {
  return {
    type: "MAIN_MINT_ACTIVE"
  };
};
const mainMintInActive = () => {
  return {
    type: "MAIN_MINT_INACTIVE"
  };
};

export const genericError = () => {
  return {
    type: "ERROR"
  };
};

export const startMinting = () => {
  return {
    type: "START_MINTING"
  };
};

export const finishMinting = () => {
  return {
    type: "FINISH_MINTING"
  };
};

const stopLoading = () => {
  return {
    type: "STOP_LOADING"
  };
};

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const setTotalMint = (payload) => {
  return {
    type: "SET_TOTAL_MINT",
    payload: payload
  };
};
const setMintPrice = (payload) => {
  return {
    type: "SET_MINT_PRICE",
    payload: payload
  };
};

const setTokenIdsOfOwner = (payload) => {
  return {
    type: "SET_TOKEN_IDS",
    payload: payload
  };
};

const setPaused = (payload) => {
  return {
    type: "SET_PAUSED",
    payload: payload
  };
};


const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

function openModalAndGetWeb3Instance(dispatch) {
  console.log('IN THE MODAL.....');

  if (!provider) {
    const web3Modal = new Web3Modal({
      cacheProvider: false,
      providerOptions // required
    });
    web3Modal.on('close', () => {
      console.log('IN THE MODAL CLOSE.....');

      dispatch(stopLoading());
    })
    web3Modal.clearCachedProvider();
    return connectToProvider(web3Modal);
  }
}

function getCSSAbi() {
  return fetch("/config/abi_css.json", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}

function getConfig() {
  return fetch("/config/config.json", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
}

async function getContractInstance() {
  const abiResponseCss = await getCSSAbi();
  const abi_css = await abiResponseCss.json();
  const configResponse = await getConfig();
  const CONFIG = await configResponse.json();

  return new Web3EthContract(
    abi_css,
    CONFIG.CONTRACT_ADDRESS_CSS
  );
}

function checkIfPremintActive(smartContractObjCss, account) {
  return async (dispatch) => {
    smartContractObjCss.methods
      .isPreMintActive().call()
      .then((isPremintActive) => {
        if (isPremintActive) {
          dispatch(premintActive());
          dispatch(hasPremintAccess(smartContractObjCss, account))
        } else dispatch(premintInActive())
      });
  }
}

export function getTokensOfOwner(smartContractObjCss, account) {
  return async (dispatch) => {
    smartContractObjCss.methods
      .tokensOfOwner(account).call()
      .then((tokenIds) => {
        if (tokenIds.length > 0) {
          dispatch(setTokenIdsOfOwner(tokenIds));
        }
      });
  }
}

function checkIfMainMintActive(smartContractObjCss) {
  return async (dispatch) => {
    smartContractObjCss.methods
      .isActive().call()
      .then((isMainMintActive) => {
        isMainMintActive ? dispatch(mainMintActive()) : dispatch(mainMintInActive())
      });
  }
}
function getMintPrice(smartContractObjCss) {
  return async (dispatch) => {
    smartContractObjCss.methods
      .price().call()
      .then((price) => {
        dispatch(setMintPrice(price))
      });
  }
}
function checkIfPaused(smartContractObjCss) {
  return async (dispatch) => {
    smartContractObjCss.methods
      .paused().call()
      .then((isPaused) => {
        dispatch(setPaused(isPaused))
      });
  }
}

function hasPremintAccess(smartContractObjCss, account) {
  return async (dispatch) => {
    smartContractObjCss.methods
      .premintAccess(account).call()
      .then((hasPremintAccess) => {
        hasPremintAccess ? dispatch(premintAccessSuccess()) : dispatch(premintAccessFailed())
      });
  }
}

export function getTotalMintAndSet(smartContractObjCss, owner) {
  return async (dispatch) => {
    smartContractObjCss.methods
      .balanceOf(owner).call()
      .then((totalMint) => {
        dispatch(setTotalMint(totalMint));
      });
  }
}

async function switchAndAddNetworkIfRequired(CONFIG) {
  try {
    await web3.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: CONFIG.isTestnet ? CONFIG.NETWORK_TESTNET.ID_HEX : CONFIG.NETWORK.ID_HEX }],
    });
    return true;
  } catch (error) {
    if (error.code === 4902) {
      const par = CONFIG.isTestnet ? [BINANCE_NETWORK_TESTNET] : [BINANCE_NETWORK_MAINNET];
      try {
        await web3.currentProvider.request({
          method: "wallet_addEthereumChain",
          params: par
        });
        await web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: CONFIG.isTestnet ? CONFIG.NETWORK_TESTNET.ID_HEX : CONFIG.NETWORK.ID_HEX }],
        });
        return true;
      } catch (error) {
        return false;
      }
    }
  }
}


export const connect = () => {
  return async (dispatch) => {
    console.log('CONNECTING.....');
    dispatch(connectRequest());

    const configResponse = await getConfig();
    const CONFIG = await configResponse.json();

    web3 = await openModalAndGetWeb3Instance(dispatch);


    //pass the provider obtained from web3Modal

    console.log("CONNECTION COMPLETE...");
    Web3EthContract.setProvider(provider);
    try {
      const accounts = await web3.eth.getAccounts();

      console.log("CONNECTED ADDRESS:", accounts[0]);
      const networkId = await web3.eth.net.getId();
      const expectedNetwork = CONFIG.isTestnet ? CONFIG.NETWORK_TESTNET.ID : CONFIG.NETWORK.ID;
      console.log("EXPECTED NETWORK:", expectedNetwork);
      if (networkId == expectedNetwork) {
        console.log("MATCHED EXPECTED NETWORK:", expectedNetwork);

        const smartContractObjCSS = await getContractInstance();

        dispatch(checkIfPaused(smartContractObjCSS));
        dispatch(getMintPrice(smartContractObjCSS));
        dispatch(checkIfMainMintActive(smartContractObjCSS));
        dispatch(checkIfPremintActive(smartContractObjCSS, accounts[0]));
        dispatch(getTokensOfOwner(smartContractObjCSS, accounts[0]));

        await setTimeout(() => {
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContractCSS: smartContractObjCSS,
              web3: web3,
            })
          );
        }, 500); // 500 mil-sec timeout is ourely for pleasurable expereince

        dispatch(getTotalMintAndSet(smartContractObjCSS, accounts[0]));
        setInterval(function () {
          dispatch(getTotalMintAndSet(smartContractObjCSS, accounts[0]));
        }, CONFIG.PROGRESS_INTERVEL);


        // Add listeners start
        ethereum.on("accountsChanged", (accounts) => {
          console.log("ON ACCOUNT CHANGE...");
          dispatch(updateAccount(accounts[0]));
        });
        ethereum.on("chainChanged", () => {
          window.location.reload();
        });
        // Add listeners end
      } else {
        message.error('Please change network to binance!');
        dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
      }
    } catch (err) {
      console.log("connection failed...");
      dispatch(connectFailed("Something went wrong."));
    }


  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
